import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className="row">
          <div className="col-md-4">
            <img src="img/dirt.jpeg" className='service-img' />
            <div className="service-desc">
              <h3>Material Hauling</h3>
              <p>We haul it all! Dirt, rock, gravel, sand and more all delivered to your job site quickly and effeciently. Servicing San Tan Valley and the surrounding areas.</p>
            </div>
          </div>
          <div className="col-md-4">
            <img src="img/simple_14.jpeg" className='service-img' />
            <div className="service-desc">
              <h3>Simple 14's</h3>
              <p>We have Simple 14 wheelers ready to haul medium sized loads (17-19 tons) and get your operation moving! Our trucks offer a quick unloading time and are excellent for high production operations.</p>
            </div>
          </div>
          <div className="col-md-4">
            <img src="img/simple_16.jpg" className='service-img' />
            <div className="service-desc">
              <h3>Simple 16's</h3>
              <p>For slightly larger-sized loads (20-22 tons) we have Simple 16 wheelers which are a great choice for High Production Jobs! If you have a large amount of material to move, these are the trucks you want!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
